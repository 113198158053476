<template>
    <div class="row">
        <div class="questionList col-3">
            <b-list-group >
                <b-list-group-item  v-for="question in questionLists" :key="question.mock_test_id" class="d-flex justify-content-between align-items-center" @click="getQuestionDetail(question['mock_test_id'])" >
                    {{question["title"]}}
                    <b-badge variant="primary" pill>1</b-badge>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
// import Timer from '../reuseable/timer'
//import $ from "jquery";
export default {
    // components:{
    //     'app-Timer':Timer
    // },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            recordMode:'press',
            recordings: [],
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'mocktest/fetchQuestion'
        }),
        ...mapGetters({
            getQuestionList: 'mocktest/questions'
        }),
        getQuestionDetail(questionId){
            this.selectedquestion = this.questionLists.filter(function (item) {
                return item.readaloud_id == questionId;
            })[0]
            this.recordings = []
            // this.startFlag = false
            // this.$nextTick(function(){
            // this.$refs.timer.setTime(40)
            // $('.ar-recorder__stop').click()
            // })
        },
        removeRecord (index) {
            this.recordings.splice(index, 1)
        },
        // timerEnd(){
        //    if(!this.startFlag) $('.ar-icon.ar-icon__lg').click()
        // },
        beforeRecording (msg) {
            //console.log('Before Record: ', msg)
            this.startFlag = false
            if(this.$refs.timer!=undefined)this.$refs.timer.setTime(0)
        },
        afterRecording(msg){
            this.startFlag = true
            ////console.log('After Record: ', msg)
            this.recordings.push({
            src: window.URL.createObjectURL(msg.blob)
            })
        },
        micCheck(msg){
            this.$alert(msg)
        }
    },
    beforeMount(){
        this.questionservice('mocktest/getallmocktest').then(()=>{
            this.questionLists = this.getQuestionList().data
        })
    }
}
</script>
<style scoped>

</style>